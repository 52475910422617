import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/img/burger.svg'


const _hoisted_1 = { class: "header_container" }
const _hoisted_2 = { class: "header_info" }
const _hoisted_3 = {
  key: 0,
  class: "header_mobile"
}

import router from '@/router';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'TheHeader',
  setup(__props) {

const isMenuOpen = ref(false)

const openMenu = () => {
    isMenuOpen.value = !isMenuOpen.value
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("header", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_RouterLink, {
        to: "/",
        class: "header_logo"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("img", {
            src: "/logo.svg",
            alt: "Header.logo"
          }, null, -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_RouterLink, {
          class: "headerInfo_link",
          to: "/rareawards"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Раритетные кубки")
          ])),
          _: 1
        }),
        _createVNode(_component_RouterLink, {
          class: "headerInfo_link",
          to: "/awards"
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode("Все награды")
          ])),
          _: 1
        }),
        _createElementVNode("form", {
          class: "headerInfo_form",
          onSubmit: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ path: '/awards' })))
        }, _cache[6] || (_cache[6] = [
          _createElementVNode("input", {
            class: "headerInfoForm_input",
            type: "text",
            placeholder: "Поиск по названию наград"
          }, null, -1)
        ]), 32)
      ]),
      _createElementVNode("button", {
        class: "header_burger",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (openMenu()))
      }, _cache[7] || (_cache[7] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "BurgerImg"
        }, null, -1)
      ]))
    ]),
    (isMenuOpen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _cache[11] || (_cache[11] = _createElementVNode("h3", { class: "headerMobile_title" }, "Меню", -1)),
          _createVNode(_component_RouterLink, {
            class: "headerInfo_link",
            to: "/rareawards"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("Раритетные кубки")
            ])),
            _: 1
          }),
          _createVNode(_component_RouterLink, {
            class: "headerInfo_link",
            to: "/awards"
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode("Все награды")
            ])),
            _: 1
          }),
          _createElementVNode("form", {
            class: "headerInfo_form",
            onSubmit: _cache[2] || (_cache[2] = ($event: any) => (_unref(router).push({ path: '/awards' })))
          }, _cache[10] || (_cache[10] = [
            _createElementVNode("input", {
              class: "headerInfoForm_input",
              type: "text",
              placeholder: "Поиск по названию наград"
            }, null, -1)
          ]), 32)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})