<template>
    <header>
        <div class="header_container">
            <RouterLink to="/" class="header_logo"><img src="/logo.svg" alt="Header.logo"></RouterLink>
            <div class="header_info">
                <RouterLink class="headerInfo_link" to="/rareawards">Раритетные кубки</RouterLink>
                <RouterLink class="headerInfo_link" to="/awards">Все награды</RouterLink>
                <form class="headerInfo_form" @submit="router.push({ path: '/awards' })">
                    <input class="headerInfoForm_input" type="text" placeholder="Поиск по названию наград">
                </form>
            </div>
            <button class="header_burger" @click="openMenu()"><img src="@/assets/img/burger.svg"
                    alt="BurgerImg"></button>
        </div>
        <div class="header_mobile" v-if="isMenuOpen">
            <h3 class="headerMobile_title">Меню</h3>
            <RouterLink class="headerInfo_link" to="/rareawards">Раритетные кубки</RouterLink>
            <RouterLink class="headerInfo_link" to="/awards">Все награды</RouterLink>
            <form class="headerInfo_form" @submit="router.push({ path: '/awards' })">
                <input class="headerInfoForm_input" type="text" placeholder="Поиск по названию наград">
            </form>
        </div>
    </header>
</template>
<script setup lang="ts">
import router from '@/router';
import { ref } from 'vue';

const isMenuOpen = ref(false)

const openMenu = () => {
    isMenuOpen.value = !isMenuOpen.value
}
</script>
<style lang="sass" scoped>
header 
    position: absolute
    top: 0
    left: 0
    width: 100%
    padding: 10px 0px
    z-index: 2
    background: #0B1428
.header
    &_container
        padding: 0px 40px
        display: flex
        align-items: center
        justify-content: space-between
    &_logo
        max-width: 124px
        width: 100%
    &_info
        display: flex
        align-items: center
        gap: 40px
        max-width: 650px
        width: 100%
    &_burger 
        display: none
    &Info
        &_link 
            font-size: 20px
            font-weight: 200
            color: #fff
        &_form 
            max-width: 266px
            width: 100%

        &Form
            &_input 
                background: rgba(217, 217, 217, 0.22)
                border-radius: 7px
                font-size: 16px
                font-weight: 200
                color: #fff
                width: 100%
                padding: 5px 0px
                padding-left: 5px
    &_mobile 
        display: none
@media screen and (max-width: 900px)
    .header 
        &_container
            padding: 0px 20px
        &_info 
           display: none
        &_burger 
            display: block
            width: 40px
        &_mobile 
            display: flex
            flex-direction: column
            width: 100%
            height: calc(100dvh - 100px)
            top: 100px
            left: 0
            background-image: url('@/assets/img/main_stars.jpg')
            background-repeat: repeat
            align-items: center
            justify-content: center
            text-align: center
            gap: 45px
        &Mobile 
            &_title 
                font-size: 36px
                font-weight: 900
                text-transform: uppercase
                color: #fff

</style>