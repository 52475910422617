import { defineStore } from 'pinia'

export const useMainStore = defineStore('mainStore', {
    state: () => ({ isErrorModalActive: false }),
    actions: {
        openErrorModal() {
            this.isErrorModalActive = true
        },
        closeErrorModal() {
            this.isErrorModalActive = false
        }
    },
})