import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/close.svg'


const _hoisted_1 = { class: "modal_bg" }
const _hoisted_2 = { class: "modal_window" }

import { useMainStore } from '@/store/mainStore';
import axios from 'axios';
import { ref } from 'vue';

const api = 'https://stage-awards.kronbars.ru'

export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorModal',
  setup(__props) {

const mainStore = useMainStore()

const fio = ref('')
const vkLink = ref('')
const issue = ref('')


const sendIssue = (e: Event) => {
    e.preventDefault()

    try {
        axios.post(api + '/api/issues', {
            fullName: fio.value,
            contact: vkLink.value,
            issueDescription: issue.value
        })
            .then((res) => {
                const result = res.data

                console.log(result.message)

                mainStore.closeErrorModal()
            })
            .catch((err) => {
                console.log('Ошибка при отправке проблемы: ' + err);
                return err;
            })
    } catch {
        console.log('Ошибка при отправке наград');
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "modalWindow_close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(mainStore).closeErrorModal()))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Close"
        }, null, -1)
      ])),
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = (e) => sendIssue(e))
      }, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "modalWindow_input",
          placeholder: "Ваше ФИО...",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((fio).value = $event))
        }, null, 512), [
          [_vModelText, fio.value]
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "modalWindow_input",
          placeholder: "Ссылка на адрес вконтакте",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((vkLink).value = $event))
        }, null, 512), [
          [_vModelText, vkLink.value]
        ]),
        _withDirectives(_createElementVNode("textarea", {
          class: "modalWindow_textarea",
          placeholder: "Опишите недочет, который вы заметили...",
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((issue).value = $event))
        }, null, 512), [
          [_vModelText, issue.value]
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("button", { class: "modalWindow_button" }, "Отправить", -1))
      ], 32)
    ])
  ]))
}
}

})