<template>
  <TheHeader></TheHeader>
  <main>
    <section class="main">
      <div class="container">
        <div class="main_block">
          <h1 class="main_title">цифровой музей <br /><span>достижений</span></h1>
        </div>
      </div>
    </section>
    <router-view />
  </main>
  <TheFooter></TheFooter>
  <ErrorModal v-if="mainStore.isErrorModalActive"></ErrorModal>
</template>
<script setup lang="ts">
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue';
import { useMainStore } from './store/mainStore';
import ErrorModal from './components/ErrorModal.vue';

const mainStore = useMainStore()



</script>
<style lang="sass">
@font-face 
  font-family: "SF-pro-display"
  src: url("~@/assets/fonts/SF-Pro-Display-Thin.otf")
  font-weight: 200
@font-face 
  font-family: "SF-pro-display"
  src: url("~@/assets/fonts/SF-Pro-Display-Semibold.otf")
  font-weight: 500
@font-face 
  font-family: "SF-pro-display"
  src: url("~@/assets/fonts/SF-Pro-Display-Regular.otf")
  font-weight: 400
@font-face 
  font-family: "SF-pro-display"
  src: url("~@/assets/fonts/SF-Pro-Display-Medium.otf")
  font-weight: 500
@font-face 
  font-family: "SF-pro-display"
  src: url("~@/assets/fonts/SF-Pro-Display-Light.otf")
  font-weight: 300
@font-face 
  font-family: "SF-pro-display"
  src: url("~@/assets/fonts/SF-Pro-Display-Heavy.otf")
  font-weight: 800
@font-face 
  font-family: "SF-pro-display"
  src: url("~@/assets/fonts/SF-Pro-Display-Bold.otf")
  font-weight: 700
@font-face 
  font-family: "SF-pro-display"
  src: url("~@/assets/fonts/SF-Pro-Display-Black.otf")
  font-weight: 900

*
  padding: 0
  margin: 0
  box-sizing: border-box
  font-family: "SF-pro-display"
  font-weight: 300
body 
  background: #EEEEEE
  position: relative
a
  color: inherit
  text-decoration: none 
  display: block 
  cursor: pointer
img
  width: 100%
  height: auto
button 
  background: 0 none 
  outline: 0 none 
  border: 0 none 
  font-family: inherit 
  cursor: pointer
li 
  list-style: none 
section
  margin-bottom: 80px
.container
  max-width: 1002px
  width: 100%
  margin: 0 auto 
input 
  border: 0 none 
  outline: 0 none 
  cursor: text

.main 
  height: 100dvh
  background: #0B1428
  border-bottom-left-radius: 30px
  border-bottom-right-radius: 30px
  background-size: cover
  background-position: center center
  background-repeat: repeat
  background-image: url('@/assets/img/main_stars.jpg')
  display: block !important
  .container
    padding: 0px 40px
    display: flex
    align-items: center
    position: relative
    height: 100%
    max-width: none
    z-index: 1
    &::before
      content: ""
      background-image: url('@/assets/img/main_line.svg')
      background-size: contain
      background-repeat: no-repeat
      position: absolute
      left: 0
      top: 50%
      transform: translateY(-50%)
      width: 580px
      height: 600px
      z-index: -1
    &::after
      content: ""
      background-image: url('@/assets/img/main_bg.svg')
      background-size: contain
      background-repeat: no-repeat
      position: absolute
      right: 0
      top: 50%
      transform: translateY(-50%)
      z-index: -1
      width: 644px
      height: 654px
  &_title
    font-size: 88px
    font-weight: 600
    color: #FFFFFF
    text-transform: uppercase
    font-family: "Oswald", sans-serif
    line-height: 100px
    text-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
    span 
      color: #268AD0
      font-family: "Oswald", sans-serif
      font-weight: 600
      line-height: 100px
      text-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
@media screen and (max-width: 1200px)
  .main 
    overflow: hidden
    .container
      justify-content: center
      align-items: flex-start
      padding-top: 130px
      text-align: center
      &::before
        display: none 
      &::after 
        right: auto
        top: 75%
        bottom: 0px
        width: 506px
        height: 504px
@media screen and (max-width: 900px)
  .container 
    padding: 0px 20px
  section 
    margin-bottom: 40px    
  .main 
    .container
      padding: 0px
      padding-top: 130px
    &_title 
      font-size: 11vw
      line-height: 44px
</style>
