<template>
    <div class="modal_bg">
        <div class="modal_window">
            <button class="modalWindow_close" @click="mainStore.closeErrorModal()"><img src="@/assets/img/close.svg" alt="Close"></button>
            <form @submit="(e) => sendIssue(e)">
                <input type="text" class="modalWindow_input" placeholder="Ваше ФИО..." v-model="fio">
                <input type="text" class="modalWindow_input" placeholder="Ссылка на адрес вконтакте" v-model="vkLink">
                <textarea class='modalWindow_textarea' placeholder="Опишите недочет, который вы заметили..." v-model="issue"></textarea>
                <button class="modalWindow_button">Отправить</button>
            </form>
        </div>
    </div>
</template>
<script setup lang="ts">
const api = 'https://stage-awards.kronbars.ru'
import { useMainStore } from '@/store/mainStore';
import axios from 'axios';
import { ref } from 'vue';

const mainStore = useMainStore()

const fio = ref('')
const vkLink = ref('')
const issue = ref('')


const sendIssue = (e: Event) => {
    e.preventDefault()

    try {
        axios.post(api + '/api/issues', {
            fullName: fio.value,
            contact: vkLink.value,
            issueDescription: issue.value
        })
            .then((res) => {
                const result = res.data

                console.log(result.message)

                mainStore.closeErrorModal()
            })
            .catch((err) => {
                console.log('Ошибка при отправке проблемы: ' + err);
                return err;
            })
    } catch {
        console.log('Ошибка при отправке наград');
    }
}
</script>
<style lang="sass" scoped>
.modal
    &_bg
        width: 100%
        height: 100dvh
        background: rgba(0, 0, 0, 0.6)  
        display: flex
        align-items: center
        justify-content: center
        position: fixed
        top: 0
        left: 0
        z-index: 1000
    &_window
        max-width: 550px
        width: 100%
        border-radius: 20px
        background-image: url('@/assets/img/main_stars.jpg')
        background-repeat: repeat
        padding: 25px 17px
        position: relative
        form 
            display: flex
            flex-direction: column
            gap: 12px
    &Window 
        &_input
            width: 100%
            background: rgba(217, 217, 217, 0.33)
            font-size: 16px
            font-weight: 200
            color: #fff
            padding: 5px 8px
            border-radius: 5px
        &_textarea
            width: 100%
            background: rgba(217, 217, 217, 0.33)
            font-size: 16px
            font-weight: 200
            color: #fff
            padding: 5px 8px
            border-radius: 5px
            resize: none 
            height: 60px
            border: 0 none 
            outline: 0 none
        &_button 
            width: 100%
            background: rgba(217, 217, 217, 1)
            padding: 5px 0px
            font-size: 14px
            border-radius: 5px
        &_close 
            width: 30px
            position: absolute
            top: -10px
            right: -10px
@media screen and (max-width: 600px)
    .modal
        &_window
            max-width: 90%
</style>