<template>
    <div class="adventages_item">
        <div class="adventagesItem_img"><img :src="api + award.photos[0].url" alt="advImg"></div>
        <div class="adventagesItem_main">
            <div class="adventagesItemMain_header">
                <h3 class="adventagesItemMain_title" :title="award.title">{{ award.title }}</h3>
                <div class="adventagesItemMain_info">
                    <div class="adventagesItemMainInfo_item">
                        <div class="adventagesItemMainInfoItem_icon"><img src="@/assets/img/icons/sport/soccer.svg"
                                alt="Icon"></div>
                        <p class="adventagesItemMainInfoItem_title">{{ award.sportType.name }}</p>
                    </div>
                    <div class="adventagesItemMainInfo_item">
                        <div class="adventagesItemMainInfoItem_icon"><img src="@/assets/img/icons/calendar.svg"
                                alt="Icon">
                        </div>
                        <p class="adventagesItemMainInfoItem_title">
                            {{ getNormalDate(award.dateReceive) }}
                        </p>
                    </div>
                    <div class="adventagesItemMainInfo_item">
                        <div class="adventagesItemMainInfoItem_icon"><img src="@/assets/img/icons/check.svg" alt="Icon">
                        </div>
                        <p class="adventagesItemMainInfoItem_title">{{ award.competitionLevel.name }}</p>
                    </div>
                    <div class="adventagesItemMainInfo_item">
                        <div class="adventagesItemMainInfoItem_icon"><img src="@/assets/img/icons/graph.svg" alt="Icon">
                        </div>
                        <p class="adventagesItemMainInfoItem_title">{{ award.place.name }}</p>
                    </div>
                </div>
                <p class="adventagesItemMain_descript">{{ award.description }}</p>
            </div>
            <RouterLink :to="'/awards/' + award.id" class="adventagesItemMain_link">Подробнее</RouterLink>
        </div>
    </div>
</template>
<script setup lang="ts">
const api = 'https://stage-awards.kronbars.ru'

import { defineProps } from 'vue';

/* eslint-disable */
defineProps<{
    award: Record<any, any>
}>()

const getNormalDate = (isoDate: string) => {

    const date = new Date(isoDate);

    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    const formattedDate = `${day}.${month}.${year}`;

    return formattedDate
}

</script>
<style lang="sass" scoped>
.adventages
    &_item 
        display: flex
        flex-direction: column
    &Item 
        &_img
            height: 210px
            display: flex
            align-items: center
            justify-content: center
            border-top-left-radius: 20px
            border-top-right-radius: 20px
            overflow: hidden
            background: url('@/assets/img/awards_bg.png')
            background-size: contain
            background-repeat: repeat-x
            background-position: center center
            img 
                height: 100%
                width: auto
        &_main
            background: #0E0E25
            padding: 10px 20px
            display: flex
            flex-direction: column
            justify-content: space-between
            border-bottom-left-radius: 20px
            border-bottom-right-radius: 20px
            flex: 1
        &Main
            &_title 
                font-size: 18px
                font-weight: 300
                color: #fff
                margin-bottom: 7px
                display: -webkit-box
                -webkit-line-clamp: 2
                -webkit-box-orient: vertical
                overflow: hidden
                height: 42px
            &_info 
                display: flex
                flex-wrap: wrap
                max-width: 90%
                gap: 4px 14px
            &Info
                &_item 
                    display: flex
                    align-items: center
                    gap: 5px
                &Item 
                    &_icon 
                        width: 12px
                    &_title 
                        color: #D2D2D2
                        font-size: 14px
                        font-weight: 300
            &_descript
                color: #D2D2D2
                font-size: 14px
                font-weight: 300
                margin-top: 19px
                display: -webkit-box
                -webkit-line-clamp: 4
                -webkit-box-orient: vertical
                overflow: hidden
            &_link 
                padding: 10px 20px
                background: #fff
                border-radius: 44px
                font-size: 12px
                font-weight: 300
                color: #0E0E25
                margin-top: 13px
                width: fit-content

            

</style>