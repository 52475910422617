import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomePage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/awards/:id',
    name: 'awardsItemPage',
    component: () => import('@/views/AwardsItemPage.vue')
  }, 
  {
    path: '/offeraward',
    name: 'offeraward',
    component: () => import('@/views/OfferAward.vue')
  }, 
  {
    path: '/awards/',
    name: 'awardsPage',
    component: () => import('@/views/AwardsPage.vue')
  }, 
  {
    path: '/rareawards/',
    name: 'rareAwardsPage',
    component: () => import('@/views/RareAwards.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
